import { renderIsomorphicComponent, renderComponent } from "@quintype/framework/client/start";
import get from "lodash/get";

import { pickComponent } from "../isomorphic/pick-component";
import { Footer } from "../isomorphic/components/layouts/footer";
// import { NavbarSearch } from "../isomorphic/components/layouts/header/navbar-search";
import { Header } from "./../isomorphic/components/layouts/header";
import { gumletScriptGenerator } from "../isomorphic/components/gumlet-script-generator";
import LoadingIndicatorComponent from "../isomorphic/components/atoms/loading-indicator";
import { OutOfPageAd } from "../isomorphic/components/ads";
import DesktopHeaderAd from "../isomorphic/components/layouts/header/header-ad";

export function preRenderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  const pageType = get(store.getState(), ["qt", "pageType"], null);

  if (pageType !== "user-login") {
    renderComponent(Footer, "footer", store, hydrate);
    renderComponent(Header, "topbar", store, hydrate);
    // renderComponent(NavbarSearch, "search-bar", store, hydrate);
    renderComponent(DesktopHeaderAd, "desktop-ad", store);
    renderComponent(OutOfPageAd, "out-of-page-ad", store);
  }
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  global.qtConfig = store && store.getState().qt.config;
  const placeholderDelay = parseInt(
    get(store.getState(), ["qt", "config", "publisher-attributes", "placeholder_delay"])
  );

  placeholderDelay && renderComponent(gumletScriptGenerator, "gumlet-script-generator", store);
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell
  });
}
