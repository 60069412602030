import React from "react";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { signInUpWithGoogleOneTap, getUserDetails } from "../../helper/utils";
import { useDispatch, useSelector } from "react-redux";
import { setIsLogin, setUserDetails } from "../../store/actions";
import { get } from "lodash";
import { addSignUpLoginToDataLayer } from "../../helper/datalayer-utils";

function GoogleOneTapSignInUp() {
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  const dispatch = useDispatch();
  return (
    <div style={{ visibility: "hidden", height: "0px" }} id="google-one-tap">
      <GoogleLogin
        onSuccess={async (response) => {
          if (response?.credential) {
            const responseSignInUpWithGoogleOneTap = await signInUpWithGoogleOneTap(response?.credential);

            const access_token = localStorage.getItem("access_token");
            const refresh_token = localStorage.getItem("refresh_token");

            if (access_token && refresh_token) {
              try {
                const data = await getUserDetails();
                if (data?.status === 401) {
                  // Remove an tokens from localStorage
                  localStorage.removeItem("access_token");
                  localStorage.removeItem("refresh_token");
                  localStorage.removeItem("user");
                  localStorage.removeItem("commentsToken");

                  googleLogout();
                  //logging out user if refresh token expires
                  window.location.href = `${ssoEndpoint}/auth/logout?redirectUrl=${window.location.href}`;
                } else {
                  addSignUpLoginToDataLayer(window.location.href, data);
                  dispatch(setIsLogin(true));
                  dispatch(setUserDetails(data || {}));
                  localStorage.setItem("user", JSON.stringify(data));
                }
              } catch (err) {
                console.log(err, "<<<< GOOGLE ONE TAP ERROR >>>>");
              }
            }
          }
        }}
        onError={(error) => {
          console.log(error, "Google one tap error");
        }}
        useOneTap={true}
      />
    </div>
  );
}

export default GoogleOneTapSignInUp;
