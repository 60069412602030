/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { array, object, string } from "prop-types";
import { Link } from "@quintype/components";

import SocialShareIcons from "../../molecules/social-share-footer";

import Button from "../../atoms/Button";
// import AppStore from "../../atoms/app-store";
// import GooglePlay from "../../atoms/google-play";
import ExapandIcon from "../../atoms/icons/expand-icon";

import FooterCollection from "./footer-collection";
import DHlogoDesktop from "../../atoms/icons/dh-logo-desktop";
import DHlogoMobile from "../../atoms/icons/dh-logo-mobile";

import "./footer.m.css";
import FooterAppStore from "../../atoms/footer-app-store";
import FooterGooglePlay from "../../atoms/footer-google-play";
import { addClickTrackingToDataLayer } from "../../helper/datalayer-utils";

const FooterBase = ({
  footerCorporateMenu,
  footerTags,
  footerSections,
  footerCollection,
  footerSecondaryMenu,
  state,
  pageType
}) => {
  const [showSection, setShowSection] = useState(false);
  const toggleSectionHandler = (idx) => {
    setShowSection(showSection === idx ? undefined : idx);
  };

  return (
    <div styleName={`footer-wrapper ${pageType == "story-page" ? "extra-space" : ""}`}>
      <div styleName="footer-icons">
        <a href="/" styleName="desktop-logo">
          <DHlogoDesktop />
        </a>
        <a href="/" styleName="mobile-logo">
          <DHlogoMobile />
        </a>
        <div styleName="footer-app-icons-wrapper">
          <a
            href="https://play.google.com/store/apps/details?id=com.tpml.dh"
            target="_blank"
            styleName="app-link"
            rel="noreferrer"
          >
            <FooterGooglePlay height="45" width="153" />
          </a>
          <a
            href="https://apps.apple.com/us/app/deccan-herald-news/id1511179557"
            target="_blank"
            styleName="app-link"
            rel="noreferrer"
          >
            <FooterAppStore height="45" width="153" />
          </a>
        </div>
      </div>
      <div styleName="row1">
        {footerSections.map((item, index) => (
          <div styleName="row1-list" key={item.id}>
            <div styleName="title-icon">
              <Link key={item.id} externalLink={item.isExternalLink} href={item && item?.url}>
                <div styleName="title" onClick={() => addClickTrackingToDataLayer(item?.url, item.title, "footer-menu")}>{item.title}</div>
              </Link>
              {item.children.length > 0 && (
                <div
                  key={index}
                  styleName={showSection === index ? "down-icon rotate-icon" : "down-icon"}
                  onClick={() => toggleSectionHandler(index)}
                  className="hide-desktop"
                >
                  <ExapandIcon />
                </div>
              )}
            </div>

            <div styleName={`collapse ${showSection === index ? "active" : ""}`}>
              {item.children?.map((i, index) => (
                <Link key={index} externalLink={item.isExternalLink} href={i.url}>
                  <div styleName="list-item-text" onClick={() => addClickTrackingToDataLayer(i.url, i.title, "footer-menu")}>{i.title}</div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div styleName="row2">
        <div styleName="button-wrapper">
          {footerTags.map((item) => {
            return item.isExternalLink ? (
              <a href={item.completeUrl} target="_blank" rel="noreferrer" key={item.id}>
                <Button styleName="btn" onClick={() => addClickTrackingToDataLayer(item.completeUrl, item.title, "footer-menu")}>
                  <p styleName="btn-title">{item.title}</p>
                </Button>
              </a>
            ) : (
              <Link key={item.id} href={item.completeUrl}>
                <Button styleName="btn" onClick={() => addClickTrackingToDataLayer(item.completeUrl, item.title, "footer-menu")}>
                  <p styleName="btn-title">{item.title}</p>
                </Button>
              </Link>
            );
          })}
        </div>
        <FooterCollection collection={footerCollection} />
      </div>
      <div styleName="row3">
        <div styleName="footer-links">
          {footerCorporateMenu.map((item, index) => {
            const idx = index + 1;
            return (
              <div key={item.id} style={{ display: "flex" }}>
                <div styleName="footer-item">
                  {item["item-type"] === "tag" ? (
                    <Link key={index} href={`/tags/${item["tag-slug"]}`}>
                      {item.title}
                    </Link>
                  ) : (
                    <a key={index} href={item?.url} target="_blank" rel="noreferrer">
                      {item.title}
                    </a>
                  )}
                </div>
                {idx > 0 && idx !== footerCorporateMenu.length && <span styleName="dot-desktop" />}
                {idx > 0 && idx !== footerCorporateMenu.length && <span styleName="dot-mobile" />}
              </div>
            );
          })}
        </div>
        <div styleName="social-share-icons">
          <SocialShareIcons />
        </div>

        <hr styleName="hr-line"></hr>

        <div styleName="footer-links">
          {footerSecondaryMenu.map((item, index) => {
            return (
              <div key={item.id} style={{ display: "flex" }}>
                <div styleName="footer-item">
                  {item["item-type"] === "tag" ? (
                    <div onClick={() => addClickTrackingToDataLayer(`${window.location.origin}/tags/${item["tag-slug"]}`, item.title, "footer-menu")}>
                      <Link key={index} href={`/tags/${item["tag-slug"]}`}>
                        {item.title}
                      </Link>
                    </div>
                  ) : (
                    <div onClick={() => addClickTrackingToDataLayer(item?.url, item.title, "footer-menu")}>
                      <a key={index} href={item?.url} target="_blank" rel="noreferrer">
                        {item.title}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    footerCorporateMenu: get(state, ["qt", "data", "navigationMenu", "footerCorporateMenu"], []),
    footerTags: get(state, ["qt", "data", "navigationMenu", "footerTags"], []),
    footerSections: get(state, ["qt", "data", "navigationMenu", "footerSections"], []),
    footerCollection: get(state, ["qt", "data", "footerCollection"], {}),
    footerSecondaryMenu: get(state, ["qt", "data", "navigationMenu", "footerSecondaryMenu"], []),
    pageType: get(state, ["qt", "pageType"])
  };
}

FooterBase.propTypes = {
  footerCorporateMenu: array,
  footerTags: array,
  footerSections: array,
  footerCollection: object,
  footerSecondaryMenu: array,
  pageType: string
};

export const Footer = connect(mapStateToProps, null)(FooterBase);
