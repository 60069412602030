import { get } from "lodash";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../../atoms/form-input";
import { getUserDetails, putUserDetails } from "../../../helper/utils";
import { setUserDetails } from "../../../store/actions";
import "./my-profile.m.css";

function MyProfile() {
  const dispatch = useDispatch();
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  const [dob, setDob] = useState(null);

  const ssoEmailTooltip =
    "Your email address is linked to a social login account. To ensure security and privacy, it cannot be updated directly";

  useEffect(() => {
    if (window) {
      const url = window.location.href.split("?")[0];
      setRedirectUrl(url);
    }
  }, []);

  useEffect(() => {
    const userData = userdetails?.userData;

    if (userData?.loginMethods[0]?.recipeUserId?.recipeUserId === userdetails?.userData?.id) {
      if (userData?.loginMethods[0]?.recipeId === "thirdparty") {
        setDisableInput(true);
      }
    }

    const dateObj = userdetails?.metadata?.dob;

    if (dateObj?.year) {
      const dateValue = new Date(dateObj.year, dateObj.month - 1, dateObj.day);
      setDob(dateValue);
    }

    setEmail(userdetails?.userData?.emails[0]);
    setFirstName(userdetails?.metadata?.first_name);
    setLastName(userdetails?.metadata?.last_name);
    setMobileNumber(userdetails?.userData?.phoneNumbers[0]);
    setSelectedGender(userdetails?.metadata?.gender);
    setDisplayName(userdetails?.metadata?.displayName);
  }, []);

  const genders = [
    { value: "male", displayName: "Male" },
    { value: "female", displayName: "Female" },
    { value: "nothing", displayName: "Prefer not to say" }
  ];

  const updateHandler = async (e) => {
    e.preventDefault();
    const date = new Date(dob);
    const dateValue = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    const updateData = {
      first_name: firstName,
      last_name: lastName,
      displayName,
      dob: dateValue,
      gender: selectedGender
    };
    putUserDetails(updateData);
    setIsUpdate(false);
    let localUserData;
    const user = window?.localStorage?.getItem("user");
    if (user !== "undefined") {
      if (typeof user === "string" && user.trim() !== "") {
        localUserData = JSON.parse(user);
      }
    }

    window.localStorage.setItem(
      "user",
      JSON.stringify({ ...localUserData, metadata: { ...localUserData?.metadata, ...updateData } })
    );
    dispatch(setUserDetails({ ...userdetails, metadata: { ...userdetails?.metadata, ...updateData } }));
    getUserDetails();
  };

  const onChangeHandler = (event, setValue) => {
    setValue(event.target.value);
    if (event.target.value) {
      setIsUpdate(true);
    }
  };
  const onDateChangeHandler = (date) => {
    setDob(date);
    setIsUpdate(true);
  };

  const updateHandelr = (inputType) => {
    const emailCallOutText =
      email === ""
        ? "Please enter the email ID you wish to use with the account."
        : `The email ID associated with this account is ${email}. Please enter the email ID you wish to use with the account.`;

    const mobileCallOutText =
      mobileNumber === ""
        ? "Please enter the mobile number you wish to use with the account."
        : `The mobile number associated with this account is ${mobileNumber}. Please enter the mobile number you wish to use with the account. `;
    if (inputType === "email") {
      window.location.href = `${ssoEndpoint}/auth/change_email?redirectUrl=${redirectUrl}&emailRequired=true&signupMessage=${emailCallOutText}&isFrom=profile`;
    } else {
      window.location.href = `${ssoEndpoint}/auth/change_phonenumber?redirectUrl=${redirectUrl}&emailRequired=false&signupMessage=${mobileCallOutText}&isFrom=profile`;
    }
  };

  return (
    <form styleName="my-account-form" onSubmit={updateHandler}>
      <div styleName="user-details-wrapper">
        <FormInput
          type="email"
          value={email}
          placeholder="Email"
          onChange={(e) => onChangeHandler(e, setEmail)}
          icon="info"
          disabled={true}
          updateBtn={!disableInput}
          tooltip={ssoEmailTooltip}
          updateHandler={() => updateHandelr("email")}
        />
        <FormInput
          type="tel"
          value={mobileNumber}
          placeholder="Mobile Number"
          onChange={(e) => onChangeHandler(e, setMobileNumber)}
          // icon={`${disableInput.mobileNumber ? "disableedit" : "editicon"}`}
          disabled={true}
          updateBtn={true}
          updateHandler={() => updateHandelr("mobile")}
        />
      </div>
      <div styleName="user-details-wrapper">
        <FormInput
          type="text"
          value={firstName}
          placeholder="First Name"
          onChange={(e) => onChangeHandler(e, setFirstName)}
          disabled={false}
          // icon="editicon"
        />
        <FormInput
          type="text"
          value={lastName}
          placeholder="Last Name"
          onChange={(e) => onChangeHandler(e, setLastName)}
          disabled={false}
          // icon="editicon"
        />
        <FormInput
          type="text"
          placeholder="Display Name"
          value={displayName}
          onChange={(e) => onChangeHandler(e, setDisplayName)}
          disabled={false}
          // icon="editicon"
        />

        <div styleName="container-dob">
          <label styleName="label-dob">Date of Birth</label>
          <div styleName="wrapper-dob">
            <DatePicker
              selected={dob}
              maxDate={new Date()}
              onChange={onDateChangeHandler}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              showIcon
              toggleCalendarOnIconClick
            />
          </div>
          {/* <SvgIconHandler type="calendericon" width="13.5px" height="15px" /> */}
        </div>
        <div styleName="gender">
          <label styleName="gender-label">Gender</label>
          <div styleName="gender-options">
            {genders.map((gender, idx) => (
              <label id={idx} styleName="gender-option">
                <input
                  type="radio"
                  value={gender.value}
                  checked={selectedGender === gender.value}
                  onChange={(e) => onChangeHandler(e, setSelectedGender)}
                  disabled={false}
                />
                {gender.displayName}
              </label>
            ))}
          </div>
        </div>
        <div styleName="wrapper-updateBtn">
          <button
            styleName={`update-btn ${isUpdate ? "update-btn-active" : "update-btn-disable"}`}
            disabled={!isUpdate}
          >
            Update
          </button>
        </div>
      </div>
      {/* <div styleName="user-details-wrapper newsletter-article">
              <span styleName="text">Newsletters</span>
              <div>
                <SvgIconHandler type="gray_right_arrow" width="25" height="24" />
              </div>
            </div>
            <div styleName="user-details-wrapper newsletter-article">
              <span styleName="text">Saved Articles</span>
              <div>
                <SvgIconHandler type="gray_right_arrow" width="25" height="24" />
              </div>
            </div> */}
    </form>
  );
}

export default MyProfile;
