function removeTokens(url) {
  const urlObj = new URL(url);
  urlObj.searchParams.delete('access_token');
  urlObj.searchParams.delete('refresh_token');
  return urlObj.toString();
}

function extractSectionNames(url) {
  const pathname = new URL(url).pathname;

  const sections = pathname
    .replace(/^\/|\/$/g, "")
    .split("/");

  return sections;
}

function convertTimestampToZTime(timestamp) {
  const date = new Date(timestamp);
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istTime = new Date(date.getTime() + istOffset);
  return istTime.toISOString().replace('Z', '') + 'Z';
}

export const pushEventToDataLayer = (eventName) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({ event: eventName });
  }
};

export const pushPageViewEvent = (eventName, url, title) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      page: {
        url: url,
        title: title
      }
    });
  }
}

export const addSignUpLoginToDataLayer = (url, data) => {
  if (typeof window !== "undefined" && data) {

    const loginSource = removeTokens(url);
    const userId = data.userData?.id;
    const marketingConsentStatus = data.metadata?.receiveMarketingAndPromitionalEmail || false;
    const newsletterConsentStatus = data.metadata?.dh?.dhNewsLetterTopNewsOfTheDay || false;
    const accountCreationDate = new Date(data.userData?.timeJoined).getTime(); // Get UTC timestamp directly

    const oneMinuteInMillis = 60 * 1000;
    const eventName = Date.now() - accountCreationDate > oneMinuteInMillis ? "login" : "signup";
    const loginMethod = data.metadata?.login_method === "phone_login" ? "mobile"
      : data.metadata?.login_method === "email_login" ? "email"
        : data.metadata?.login_method === "google_login" ? "google" : "unknown";

    window.dataLayer.push({
      event: eventName,
      login_source: loginSource,
      user_id: userId,
      authentication_method: loginMethod,
      marketing_consent_status: marketingConsentStatus,
      newsletter_consent_status: newsletterConsentStatus,
      account_creation_date: convertTimestampToZTime(accountCreationDate), // Use formatted IST time for display only
    });
  }
};

export const addLogoutToDataLayer = (url, data) => {
  if (typeof window !== "undefined" && data) {
    window.dataLayer.push({
      event: "logout",
      login_source: removeTokens(url),
      user_id: data.userData.id,
    });
  }
}

export const addAuthTrackingToDataLayer = (data) => {
  if (typeof window !== "undefined" && data) {
    window.dataLayer.push({
      event: "auth",
      user_id: data.userData.id,
      auth_status: "authenticated"
    });
  }
}

export const addClickTrackingToDataLayer = (url, text, id) => {
  if (typeof window !== "undefined") {
    window.dataLayer.push({
      event: "menuClick",
      click_id: id,
      click_text: text,
      click_url: url,
    });
  }
}

export const addStoryPageViewToDataLayer = (story, commentCount) => {
  if (typeof window !== "undefined" && story) {
    const storyTags = story.tags?.map(tag => tag.name) || [];

    const contentInfo = {
      content_id: story["story-content-id"] || "",
      content_type: "story",
      content_template: story["story-template"] || "",
      public_identifier: story["public-identifier"] || "",
      language: story?.metadata?.language || "english",
    };

    const authorNames = story?.authors?.map(author => author.name) || ["unknown"];

    const storyDetails = {
      page_type: "story",
      story_template: story["story-template"],
      section_name: extractSectionNames(story.sections?.[0]?.["section-url"]).map(
        section => section.charAt(0).toUpperCase() + section.slice(1)
      ) || "unknown",
      author_name: authorNames,
      creator_name: story["owner-name"] || "unknown",
    };

    window.dataLayer.push({
      event: "storyPageView",
      content_info: contentInfo,
      story_details: storyDetails,
      number_comments: commentCount,
      story_tags: storyTags,
      story_words: story["word-count"] || 0,
      published_date: convertTimestampToZTime(story["first-published-at"]) || "",
      last_updated: convertTimestampToZTime(story["last-published-at"]) || "",
    });
  }
};

