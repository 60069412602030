import { Link } from "@quintype/components";
import { get } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import HamburgerMyAccountButton from "../../atoms/hamburger-my-account-button";
import Logo from "../../atoms/icons/logo";
import LoginButton from "../../atoms/login-button";
import Modal from "../../atoms/modal";
import ReadEPaperButton from "../../atoms/read-ePaper-button";
import SubscribeToNewsletter from "../../atoms/subscribe-to-newsletter";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { OPEN_HAMBURGER_MENU, OPEN_SEARCHBAR, setModalToggle } from "../../store/actions";
import { getColumnMenus } from "../../utils";
import FollowUsSocialMedia from "../follow-us-social-media";
import HamburgerMenuList from "../hamburger-menu-list";
import MyAccount from "../my-account";
import "./desktop-hamburger-expand.m.css";
import { addClickTrackingToDataLayer } from "../../helper/datalayer-utils";

function DesktopHamburgerExpand({ onClose }) {
  const [navSearchText, setNavSearchText] = useState("");
  const dispatch = useDispatch();
  const getState = useSelector((state) => state);
  const primaryMenus = get(getState, ["qt", "data", "navigationMenu", "primaryMenus"], []);
  const secondaryMenus = get(getState, ["qt", "data", "navigationMenu", "secondaryMenus"], []);
  const isUserLogin = get(getState, ["isLogin"], {});
  const isModalOpen = get(getState, ["isModalOpen"], false);
  const [showModal, setShowModal] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState({ columnIdx: null, menuIdx: null });
  const submenuRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const originalStyle = document.body.style.overflow; // Store the original overflow style

      if (isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }

      // Cleanup function to revert the overflow style to the original value
      return () => {
        document.body.style.overflow = originalStyle;
      };
    }
  }, [isModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target) && !event.target.closest(".expand-btn")) {
        setExpandedMenu({ columnIdx: null, menuIdx: null });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [submenuRef]);

  if (!isModalOpen) {
    return null;
  }
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const navigateToPage = (url) => global.app.navigateToPage(dispatch, url);

  const hamburgerCloseHandler = () => {
    dispatch(setModalToggle(false));
  };
  const handleSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      navigateToPage(`/search?q=${navSearchText}`);
      hamburgerCloseHandler();
    }
  };
  const allMenus = [...primaryMenus, ...secondaryMenus];

  const columnsWithMenus = getColumnMenus(allMenus, 4);

  const expandButtonHandler = (columnIdx, menuIdx) => {
    if (expandedMenu.columnIdx === columnIdx && expandedMenu.menuIdx === menuIdx) {
      setExpandedMenu({ columnIdx: null, menuIdx: null });
    } else {
      setExpandedMenu({ columnIdx, menuIdx });
    }
  };
  return (
    <Modal onClose={hamburgerCloseHandler} isOpen={isModalOpen}>
      <div styleName="hamburger-wrapper">
        <div styleName="hamburger-top-logo">
          <Link href="/">
            <SvgIconHandler type="simpledhlogo" width="77.37px" height="42.94px" />
          </Link>
        </div>
        <div styleName="hamburger-search-menus">
          <div styleName="hamburger-search-box">
            <input
              placeholder="Search for News, Photos and Videos"
              value={navSearchText}
              onChange={(e) => setNavSearchText(e.target.value)}
              onKeyDown={handleSearchOnEnter}
            />
            <Link href={`/search?q=${navSearchText}`}>
              <button
                aria-label="search-button"
                styleName="search-box"
                type="submit"
                key="2"
                onClick={hamburgerCloseHandler}
              >
                <div styleName="normal-search">
                  <SvgIconHandler type="hamburgersearchicon" height="18px" width="18px" />
                </div>
              </button>
            </Link>
          </div>
          <div styleName="close-btn-wrapper" onClick={hamburgerCloseHandler}>
            <SvgIconHandler type="close_small" height="14" width="14" />
          </div>
          <div styleName="hamburger-menus">
            {columnsWithMenus.map((column, columnID) => (
              <div styleName="hamburger-menu-column" key={columnID}>
                {column.map((menu, menuID) => (
                  <div styleName="hamburger-menu-wrapper">
                    <Link href={menu.url} key={menuID} styleName="hamburger-menu">
                      <div onClick={() => addClickTrackingToDataLayer(menu.url, menu.title, "hamburger-menu")}>
                        {menu.title}
                      </div>
                    </Link>
                    <div styleName="submenu-wrapper">
                      {menu?.children.length > 0 && (
                        <div
                          className="expand-btn"
                          styleName="expand-btn"
                          onClick={() => expandButtonHandler(columnID, menuID)}
                        >
                          <SvgIconHandler type="expand_more_black" />
                        </div>
                      )}
                      {expandedMenu.columnIdx === columnID && expandedMenu.menuIdx === menuID && (
                        <div styleName="sub-menu-list" ref={submenuRef}>
                          <HamburgerMenuList menus={menu.children} isSingleMenu={menu?.children.length === 1} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}

            <div styleName="hamburger-menu-column">
              <div styleName="hamburger-last-column-menu">
                {isUserLogin ? <HamburgerMyAccountButton onAccountHandler={openModal} /> : <LoginButton />}
              </div>
              <Modal onClose={closeModal} isOpen={showModal}>
                <MyAccount closeModal={closeModal} />
              </Modal>
              <div styleName="hamburger-last-column-menu">
                <ReadEPaperButton />
              </div>
              <div styleName="hamburger-last-column-menu">
                <SubscribeToNewsletter />
              </div>
              <div styleName="hamburger-last-column-menu">
                <FollowUsSocialMedia />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DesktopHamburgerExpand;
