import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { useSelector, useDispatch } from "react-redux";
import { OPEN_HAMBURGER_MENU, OPEN_SEARCHBAR } from "../../../store/actions";

import { MenuItem } from "../../menu-item";
import Logo from "../../../atoms/icons/logo";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import CloseIcon from "../../../atoms/icons/close";

import "./hamburger-menus.m.css";
import { addClickTrackingToDataLayer } from "../../../helper/datalayer-utils";

const HamburgerMenus = () => {
  const dispatch = useDispatch();
  const getState = useSelector((state) => state);
  const isHamburgerMenuOpen = get(getState, ["isHamburgerMenuOpen"], false);

  const primaryMenus = get(getState, ["qt", "data", "navigationMenu", "primaryMenus"], []);
  const secondaryMenus = get(getState, ["qt", "data", "navigationMenu", "secondaryMenus"], []);

  const [navSearchText, setNavSearchText] = useState("");
  const [showPrimaryMenuSection, setShowPrimaryMenuSection] = useState(false);
  const [showSecondaryMenuSection, setShowSecondaryMenuSection] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isHamburgerMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }
  }, [isHamburgerMenuOpen]);

  if (!isHamburgerMenuOpen) {
    return null;
  }

  const navigateToPage = (url) => global.app.navigateToPage(dispatch, url);

  const handleSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      navigateToPage(`/search?q=${navSearchText}`);
      toggleHandler();
    }
  };

  const toggleHandler = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: !isHamburgerMenuOpen
    });
    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: false
    });
    setShowPrimaryMenuSection(false);
    setShowSecondaryMenuSection(false);
  };

  const showPrimarySectionHandler = (idx) => {
    setShowPrimaryMenuSection(showPrimaryMenuSection === idx ? undefined : idx);
  };

  const showSecondarySectionHandler = (idx) => {
    setShowSecondaryMenuSection(showSecondaryMenuSection === idx ? undefined : idx);
  };

  return (
    <>
      <div styleName="overlay" onClick={() => toggleHandler()}></div>
      <ul styleName={`dropdown-content ${isHamburgerMenuOpen ? "show-menus" : "hide-menus"}`}>
        <div styleName="navbar-top-wrapper">
          <div styleName="hamburger-head">
            <div onClick={() => toggleHandler()}>
              <Link href="/">
                <Logo height="40" width={38} onClick={() => toggleHandler()} />
              </Link>
            </div>
            <div styleName="hamburger-close" onClick={() => toggleHandler()}>
              <CloseIcon fill="#FFF" />
            </div>
          </div>
          <div styleName="hamburger-search-box">
            <input
              placeholder="Search"
              value={navSearchText}
              onChange={(e) => setNavSearchText(e.target.value)}
              onKeyDown={handleSearchOnEnter}
            />
            <Link href={`/search?q=${navSearchText}`}>
              <button aria-label="search-button" styleName="search-box" type="submit" key="2" onClick={toggleHandler}>
                <div styleName="normal-search">
                  <SvgIconHandler type="blacksearch" height="20px" width="20px" />
                </div>
              </button>
            </Link>
          </div>
        </div>
        <div>
          {primaryMenus.length > 0 &&
            primaryMenus.map((item, index) => {
              return (
                <>
                  <li key={index} styleName="dropdown dropdown-list">
                    <MenuItem
                      menuStyle="menu-link"
                      type="hamburger"
                      item={item}
                      toggleHandler={() => toggleHandler()}
                      changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
                      changeTextColor={false}
                    />
                    {item.children?.length > 0 && (
                      <button
                        aria-label="circle-down-arrow-button"
                        styleName={showPrimaryMenuSection === index ? "down-arrow-rotate down-arrow" : "down-arrow"}
                        type="submit"
                        key="2"
                        onClick={() => showPrimarySectionHandler(index)}
                      >
                        <SvgIconHandler type="circledownarrow" />
                      </button>
                    )}
                  </li>
                  {showPrimaryMenuSection === index && (
                    <div styleName="submenu">
                      {item.children.length > 0 && (
                        <div styleName="ddsection-submenu">
                          <div styleName="ddsec1-submenu">
                            {item.children.slice(0, 8).map((menuItem, index) => (
                              <Link
                                key={index}
                                externalLink={menuItem.isExternalLink}
                                href={menuItem && menuItem?.url}
                                onClick={toggleHandler}
                                style={{
                                  fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                }}
                              >
                                <li
                                  onClick={() => {
                                    addClickTrackingToDataLayer(menuItem?.url || '/', menuItem.title, 'hamburger-menu');
                                    toggleHandler();
                                  }}
                                >
                                  {menuItem.title}
                                </li>
                              </Link>
                            ))}
                          </div>

                          {item.children.length >= 8 && (
                            <div styleName="ddsec2-submenu">
                              {item.children.slice(8, 17).map((menuItem, index) => (
                                <Link
                                  key={index}
                                  externalLink={menuItem.isExternalLink}
                                  href={menuItem && menuItem?.url}
                                  onClick={toggleHandler}
                                  style={{
                                    fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                  }}
                                >
                                  <li
                                    onClick={() => {
                                      addClickTrackingToDataLayer(menuItem?.url || '/', menuItem.title, 'hamburger-menu');
                                      toggleHandler();
                                    }}
                                  >
                                    {menuItem.title}
                                  </li>
                                </Link>
                              ))}
                            </div>
                          )}
                          {item.children.length >= 17 && (
                            <div styleName="ddsec3-submenu">
                              {item.children.slice(17, 26).map((menuItem, index) => (
                                <Link
                                  key={index}
                                  externalLink={menuItem.isExternalLink}
                                  href={menuItem && menuItem?.url}
                                  onClick={toggleHandler}
                                  style={{
                                    fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                  }}
                                >
                                  <li
                                    onClick={() => {
                                      addClickTrackingToDataLayer(menuItem?.url || '/', menuItem.title, 'hamburger-menu');
                                      toggleHandler();
                                    }}
                                  >
                                    {menuItem.title}
                                  </li>
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
          {secondaryMenus.length > 0 &&
            secondaryMenus.map((item, index) => {
              return (
                <>
                  <li id="secondaryMenus" key={index} styleName="dropdown dropdown-list">
                    <MenuItem
                      menuStyle="menu-link"
                      type="hamburger"
                      item={item}
                      toggleHandler={() => toggleHandler()}
                      changeTextColor={false}
                      changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
                    />
                    {item.children?.length > 0 && (
                      <button
                        aria-label="circle-down-arrow-button"
                        styleName={showSecondaryMenuSection === index ? "down-arrow-rotate down-arrow" : "down-arrow"}
                        type="submit"
                        key="2"
                        onClick={() => showSecondarySectionHandler(index)}
                      >
                        <SvgIconHandler type="circledownarrow" />
                      </button>
                    )}
                  </li>
                  {showSecondaryMenuSection === index && (
                    <div styleName="submenu">
                      {item.children.length > 0 && (
                        <div styleName="ddsection-submenu">
                          <div styleName="ddsec1-submenu">
                            {item.children.slice(0, 8).map((menuItem, index) => (
                              <Link
                                key={index}
                                externalLink={menuItem.isExternalLink}
                                href={menuItem && menuItem?.url}
                                onClick={toggleHandler}
                                style={{
                                  fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                }}
                              >
                                <li
                                  onClick={() => {
                                    addClickTrackingToDataLayer(menuItem?.url || '/', menuItem.title, 'hamburger-menu');
                                    toggleHandler();
                                  }}
                                >
                                  {menuItem.title}
                                </li>
                              </Link>
                            ))}
                          </div>

                          {item.children.length >= 8 && (
                            <div styleName="ddsec2-submenu">
                              {item.children.slice(8, 17).map((menuItem, index) => (
                                <Link
                                  key={index}
                                  externalLink={menuItem.isExternalLink}
                                  href={menuItem && menuItem?.url}
                                  onClick={toggleHandler}
                                  style={{
                                    fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                  }}
                                >
                                  <li
                                    onClick={() => {
                                      addClickTrackingToDataLayer(menuItem?.url || '/', menuItem.title, 'hamburger-menu');
                                      toggleHandler();
                                    }}
                                  >
                                    {menuItem.title}
                                  </li>
                                </Link>
                              ))}
                            </div>
                          )}
                          {item.children.length >= 17 && (
                            <div styleName="ddsec3-submenu">
                              {item.children.slice(17, 26).map((menuItem, index) => (
                                <Link
                                  key={index}
                                  externalLink={menuItem.isExternalLink}
                                  href={menuItem && menuItem?.url}
                                  onClick={toggleHandler}
                                  style={{
                                    fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                  }}
                                >
                                  <li
                                    onClick={() => {
                                      addClickTrackingToDataLayer(menuItem?.url || '/', menuItem.title, 'hamburger-menu');
                                      toggleHandler();
                                    }}
                                  >
                                    {menuItem.title}
                                  </li>
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </ul>
    </>
  );
};

export default HamburgerMenus;
