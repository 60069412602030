import React, { useEffect, useState, useRef } from "react";
import { Link } from "@quintype/components";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";

import Modal from "../modal";
import HamburgerDHLogo from "../icons/hamburger-dh-logo";
import ReadEPaperButton from "../read-ePaper-button";
import LoginButton from "../login-button";
import HamburgerMyAccountButton from "../hamburger-my-account-button";
import MyAccount from "../../molecules/my-account";
import SubscribeToNewsletter from "../subscribe-to-newsletter";
import { SvgIconHandler } from "../svg-icon-hadler";
import { MenuItem } from "../../layouts/menu-item";
import "./hamburger-search-content.m.css";
import { OPEN_SEARCHBAR, setModalToggle } from "../../store/actions";
import { addClickTrackingToDataLayer } from "../../helper/datalayer-utils";

const SOCIAL_MEDIA_CONTENT = [
  { link: "https://www.whatsapp.com/channel/0029Va4ifN6AYlULZASc7V3S", icon: "whatsapp_black" },
  { link: "https://www.facebook.com/deccanherald/", icon: "facebook_black" },
  { link: "https://twitter.com/deccanherald", icon: "twitter_black" },
  { link: "https://www.instagram.com/deccanherald/", icon: "instagram_black" },
  { link: "https://www.youtube.com/channel/UCHDXXHPrz-1GVbK_qk82hBQ", icon: "youtube_black" },
  { link: "https://t.me/DeccanHeraldNews", icon: "telegram_black" }
];

export default function HamburgerSearchContent({ closeModal }) {
  const dispatch = useDispatch();
  const getState = useSelector((state) => state);
  const isUserLogin = useSelector((state) => get(state, ["isLogin"], {}));

  const [showMenuSection, setShowMenuSection] = useState(false);
  const [changeTextColor, setChangeTextColor] = useState(false);
  const [myAccountShowModal, setmyAccountShowModal] = useState(false);
  const [navSearchText, setNavSearchText] = useState("");

  const mobile = get(getState, ["qt", "data", "navigationMenu", "primaryMenus"], []);
  const mobileSecondaryMenu = get(getState, ["qt", "data", "navigationMenu", "secondaryMenus"], []);
  const combinedMenus = mobile.concat(mobileSecondaryMenu);
  const isModalOpen = get(getState, ["isModalOpen"], false);
  const submenuRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        submenuRef.current &&
        !submenuRef.current.contains(event.target) &&
        !event.target.closest(".menu-items-wrapper")
      ) {
        setShowMenuSection(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!isModalOpen) {
    return null;
  }
  const myAccountOpenModal = () => {
    setmyAccountShowModal(true);
  };
  const myAccountCloseModal = () => {
    setmyAccountShowModal(false);
  };

  const showMenuSectionHandler = (idx) => {
    setShowMenuSection(showMenuSection === idx ? undefined : idx);
  };
  const clickHandler = (url, title) => {
    addClickTrackingToDataLayer(url, title, 'hamburger-menu');
    closeModal();
  };
  const navigateToPage = (url) => global.app.navigateToPage(dispatch, url);
  const hamburgerCloseHandler = () => {
    dispatch(setModalToggle(false));
    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: false
    });
  };
  const handleSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      navigateToPage(`/search?q=${navSearchText}`);
      hamburgerCloseHandler();
    }
  };

  return (
    // <Modal onClose={hamburgerCloseHandler} isOpen={isModalOpen}>
    <div styleName="main-wrapper">
      <div onClose={hamburgerCloseHandler} styleName="hamburger-search-wrapper">
        <div styleName="wrapper">
          <div styleName="modal-head">
            <div onClick={() => closeModal()}>
              <Link href="/">
                <HamburgerDHLogo height={46} onClick={hamburgerCloseHandler} />
              </Link>
            </div>
            <div styleName="close-btn-wrapper" onClick={hamburgerCloseHandler}>
              <SvgIconHandler type="close_small" height="11" width="11" />
            </div>
          </div>
          <div styleName="modal-search-box">
            <input
              type="text"
              placeholder="Search"
              value={navSearchText}
              onChange={(e) => setNavSearchText(e.target.value)}
              onKeyDown={handleSearchOnEnter}
            />
            <Link href={`/search?q=${navSearchText}`}>
              <button
                aria-label="search-button"
                onClick={hamburgerCloseHandler}
                styleName="search-box"
                type="submit"
                key="2"
              >
                <SvgIconHandler type="search_black" width="25" height="24" />
              </button>
            </Link>
          </div>
          <div styleName="btns-wrapper">
            <ReadEPaperButton />
            {isUserLogin ? <HamburgerMyAccountButton onAccountHandler={myAccountOpenModal} /> : <LoginButton />}
            <Modal onClose={myAccountCloseModal} isOpen={myAccountShowModal}>
              <MyAccount closeModal={myAccountCloseModal} />
            </Modal>
          </div>
          <div styleName="newsletter-wrapper">
            <SubscribeToNewsletter />
          </div>
        </div>
        <div styleName="menulist">
          {combinedMenus.length > 0 &&
            combinedMenus.map((item, index) => (
              <div key={item.id} ref={submenuRef} className="menu-items-wrapper">
                <li key={item.id} styleName="dropdown menu-main">
                  <MenuItem type="hamburger" menuStyle="menu-link" item={item} toggleHandler={() => closeModal()} />
                  {item.children?.length > 0 && (
                    <button
                      aria-label="down-arrow-button"
                      styleName={showMenuSection === index ? "down-arrow-rotate down-arrow" : "down-arrow"}
                      type="submit"
                      key="2"
                      onClick={() => showMenuSectionHandler(index)}
                    >
                      <SvgIconHandler type="expand_more_black" width="13px" height="13px" />
                    </button>
                  )}
                </li>

                {showMenuSection === index && (
                  <div styleName="submenu">
                    {item.children.length > 0 && (
                      <div styleName="ddsection-submenu">
                        <div styleName="triangle"></div>
                        <div styleName={item.children.length === 1 ? "ddsec1-submenu single-child" : "ddsec1-submenu"}>
                          {item.children.slice(0, 11).map((menuItem, index) => (
                            <Link
                              key={menuItem.id}
                              externalLink={menuItem.isExternalLink}
                              href={menuItem && menuItem?.url}
                              onClick={closeModal}
                            >
                              <li
                                styleName={changeTextColor === index ? "navbar-li_active" : ""}
                                onClick={() => clickHandler(menuItem && menuItem?.url, menuItem.title)}
                              >
                                {menuItem.title}
                              </li>
                            </Link>
                          ))}
                        </div>

                        {item.children.length >= 11 && (
                          <div
                            styleName={item.children.length === 1 ? "ddsec2-submenu single-child" : "ddsec1-submenu"}
                          >
                            {item.children.slice(11, 22).map((menuItem, index) => (
                              <Link
                                key={menuItem.id}
                                externalLink={menuItem.isExternalLink}
                                href={menuItem && menuItem?.url}
                                onClick={closeModal}
                              >
                                <li
                                  styleName={changeTextColor === index ? "mobnav-li_active" : ""}
                                  onClick={() => clickHandler(menuItem && menuItem?.url, menuItem.title)}
                                >
                                  {menuItem.title}
                                </li>
                              </Link>
                            ))}
                          </div>
                        )}
                        {item.children.length >= 22 && (
                          <div
                            styleName={item.children.length === 1 ? "ddsec3-submenu single-child" : "ddsec1-submenu"}
                          >
                            {item.children.slice(22, 32).map((menuItem, index) => (
                              <Link
                                key={menuItem.id}
                                externalLink={menuItem.isExternalLink}
                                href={menuItem && menuItem?.url}
                                onClick={closeModal}
                              >
                                <li
                                  styleName={changeTextColor === index ? "mobnav-li_active" : ""}
                                  onClick={() => clickHandler(menuItem && menuItem?.url, menuItem.title)}
                                >
                                  {menuItem.title}
                                </li>
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
        <div styleName="modal-bottom">
          <span>
            <h2>Follow Us</h2>
          </span>
          <div styleName="subscribe-social-icons">
            {SOCIAL_MEDIA_CONTENT?.map((item, index) => (
              <Link href={item.link} key={index} target="_blank">
                <SvgIconHandler type={item.icon} height="32px" width="32px" />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
    // </Modal>
  );
}
