import React, { useEffect, useState } from "react";

import { Link } from "@quintype/components";
import PT from "prop-types";

import HambugerMenuIcon from "../../atoms/icons/hamburger-menu";
import NavBarHomeIcon from "../../atoms/icons/navbar-home-icon";
import NavBarSheetIcon from "../../atoms/icons/navbar-sheet-icon";
import NavBarCarousalIcon from "../../atoms/icons/navbar-carousal-icon";

import "./sticky-navbar.m.css";
import { addClickTrackingToDataLayer } from "../../helper/datalayer-utils";

const StickyNavbar = ({ onMenuToggle }) => {
  const [url, setURL] = useState("");
  useEffect(() => {
    if (location.pathname) {
      setURL(location.pathname);
    }
  }, []);

  return (
    <div styleName="sticky-navbar-mobile-wrapper">
      <button styleName={url === "/" ? "active-menu icon" : "icon"} onClick={() => addClickTrackingToDataLayer(`${window.location.origin}/`, "Home", "sticky-bottom-menu")}>
        <Link styleName={url === "/" ? "active-menu icon-link" : "icon-link"} href="/">
          <NavBarHomeIcon color={url === "/" ? "#0091AC" : "#000"} />
          <span styleName="menu-title">Home</span>
          <div styleName="hover-bar"></div>
        </Link>
      </button>
      <button styleName={url.includes("/news-shots") ? "active-menu icon" : "icon"} onClick={() => addClickTrackingToDataLayer(`${window.location.origin}/news-shots`, "News Shots", "sticky-bottom-menu")}>
        <Link styleName={url.includes("/news-shots") ? "active-menu icon-link" : "icon-link"} href={"/news-shots"}>
          <NavBarSheetIcon color={url.includes("/news-shots") ? "#0091AC" : "#000"} />
          <span styleName="menu-title">News Shots</span>
          <div styleName="hover-bar"></div>
        </Link>
      </button>
      <button styleName={url.includes("/trending") ? "active-menu icon" : "icon"} onClick={() => addClickTrackingToDataLayer(`${window.location.origin}/trending`, "Trending", "sticky-bottom-menu")}>
        <Link styleName={url.includes("/trending") ? "active-menu icon-link" : "icon-link"} href={"/trending"}>
          <NavBarCarousalIcon color={url.includes("/trending") ? "#0091AC" : "#000"} />
          <span styleName="menu-title">Trending</span>
          <div styleName="hover-bar"></div>
        </Link>
      </button >
      <div onClick={onMenuToggle} styleName="icon">
        <HambugerMenuIcon />
        <span styleName="menu-title">Menu</span>
        <div styleName="hover-bar"></div>
      </div>
    </div>
  );
};

StickyNavbar.propTypes = {
  onMenuToggle: PT.func
};

export default StickyNavbar;
