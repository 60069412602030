import React from "react";
import { Link } from "@quintype/components";

import "./footer-collection.m.css";
import { addClickTrackingToDataLayer } from "../../../helper/datalayer-utils";

const FooterCollection = ({ collection }) => {
  if (!collection.items) return null;

  const collections = collection?.items?.filter((i) => i.type === "collection");

  let id = ""

  const handleDataLayers = (collection, story = null) => {
    if (typeof window !== "undefined") {
      if (collection?.name === "Home Explainers") {
        id = "footer-news-column-explainers"
      } else if (collection?.name === "What's Brewing") {
        id = "footer-news-column-whats-brewing"
      } else if (collection?.name === "RHS Latest News") {
        id = "footer-news-column-latest-news"
      }

      if (story) {
        addClickTrackingToDataLayer(story.url, story.headline, id);
      } else {
        addClickTrackingToDataLayer(`${window.location.origin}/${collection.slug}`, collection.metadata?.["display-name"] || collection?.name, id);
      }
    }
  }

  return (
    <div styleName="footer-collections">
      {collections.map((collection, index) => {
        const stories = collection?.items?.filter((i) => i.type === "story").map((i) => i.story);

        return (
          <div key={index} styleName="collection-item">
            <div styleName="collection-title" onClick={() => handleDataLayers(collection)}>
              <Link href={`/${collection.slug}`} styleName="footer-collection-href">
                {collection.metadata?.["display-name"] || collection?.name}
              </Link>
            </div>

            <div>
              <ul>
                {stories?.splice(0, 4).map((story, index) => (
                  <li key={index} styleName="footer-story-name">
                    <div onClick={() => handleDataLayers(collection, story)}>
                      <Link href={`/${story.slug}`} key={index}>
                        <span styleName="dot"></span>
                        <span className="line-clamp line-clamp-2" styleName="collection-data" key={index}>
                          {story.headline}
                        </span>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FooterCollection;
