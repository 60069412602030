import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { throttle, get } from "lodash";

import DesktopHeader from "./desktop";
import MobileHeader from "./mobile";
import StickyBanner from "../../molecules/sticky-banner";
import { getUserDetails, addTokensToLocalStorage } from "../../helper/utils";
// import ElectionFloatingWidget from "../../atoms/election-floating-widget";

import {
  setIsLoading,
  setIsLogin,
  setMakeSecondaryApiCallsTriggered,
  setNewNotification,
  setNotificationList,
  setUserDetails
} from "../../store/actions";
import { GoogleOAuthProvider, googleLogout } from "@react-oauth/google";

import GoogleOneTapSignInUp from "../../atoms/google-one-tap";
import { addAuthTrackingToDataLayer, addSignUpLoginToDataLayer } from "../../helper/datalayer-utils";

// Common wrapper for navigation. We could add OffcanvasMenu, Navbar etc components here.

const Header = () => {
  const dispatch = useDispatch();
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  const makeSecondaryApiCallsDelay = 2250;
  const [makeSecondaryApiCalls, setMakeSecondaryApiCalls] = useState(false);
  const [isMobile, setMobile] = useState(true);
  const [loadOneTap, setGoogleOneTap] = useState(false);
  const GoogleClientId = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "google_client_id"], "")
  );

  const getScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 700) {
        !makeSecondaryApiCalls && setMakeSecondaryApiCalls(true);
      }
    }
  };

  if (typeof window !== "undefined") {
    let localUserData;
    const user = window?.localStorage?.getItem("user");
    if (user !== "undefined") {
      if (typeof user === "string" && user.trim() !== "") {
        localUserData = JSON.parse(user);
      }
    }

    if (localUserData) {
      useEffect(() => {
        addAuthTrackingToDataLayer(localUserData);
      }, []);
    }
  }

  useEffect(() => {
    if (window && window.innerWidth < 1025) {
      setMobile(true);
    } else {
      setMobile(false);
      setMakeSecondaryApiCalls(true);
    }

    if (window) {
      let searchQuery = window.location.search;
      let accessTokenIndex = searchQuery.indexOf("?access_token");
      let output = accessTokenIndex !== -1 ? searchQuery.substring(accessTokenIndex) : searchQuery;
      const searchParams = new URLSearchParams(output);
      const access_token = searchParams.get("access_token");
      const refresh_token = searchParams.get("refresh_token");
      const userLocalStorage = localStorage.getItem("user");
      const stored_access_token = localStorage.getItem("access_token");
      const stored_refresh_token = localStorage.getItem("refresh_token");

      if (stored_refresh_token && stored_access_token) {
        dispatch(setIsLogin(true));
      }
      if (access_token && refresh_token) {
        addTokensToLocalStorage(access_token, refresh_token);
        getUserDetails()
          .then((data) => {
            if (data?.status === 401) {
              // Remove an tokens from localStorage
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("user");
              localStorage.removeItem("commentsToken");

              googleLogout();
              //loggingout user if refresh token expires
              window.location.href = `${ssoEndpoint}/auth/logout?redirectUrl=${window.location.href.split("?")[0]}`;
            } else {
              dispatch(setIsLogin(true));
              dispatch(setUserDetails(data || {}));
              localStorage.setItem("user", JSON.stringify(data));
              const searchParam = accessTokenIndex !== -1 ? searchQuery.substring(0, accessTokenIndex) : searchQuery;
              window.history.replaceState({}, "", window.location.pathname + searchParam);

              addSignUpLoginToDataLayer(window.location.href, data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (userLocalStorage && userLocalStorage !== "undefined" && userdetails?.userData === undefined) {
        if (typeof userLocalStorage === "string" && userLocalStorage.trim() !== "") {
          dispatch(setUserDetails(JSON.parse(userLocalStorage)));
        }
      } else {
        setGoogleOneTap(true);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", throttle(getScroll, 250));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  useEffect(() => {
    makeSecondaryApiCalls &&
      dispatch(setMakeSecondaryApiCallsTriggered(makeSecondaryApiCalls, makeSecondaryApiCallsDelay, isMobile));
  }, [makeSecondaryApiCalls]);

  useEffect(() => {
    _izq.push([
      "getNotificationFeed",
      function (response) {
        if (response?.response !== "success") {
          dispatch(setIsLoading(true));
        } else {
          dispatch(setIsLoading(false));
          dispatch(setNotificationList(response.data));
          const latestNotificationCreatedAt = window.localStorage.getItem("latestNotificationCreatedAt");

          if (response.data[0]?.createdAt !== latestNotificationCreatedAt || latestNotificationCreatedAt == null) {
            dispatch(setNewNotification(true));
          } else {
            dispatch(setNewNotification(false));
          }
        }
      }
    ]);
  }, []);

  return (
    <GoogleOAuthProvider clientId={GoogleClientId}>
      {loadOneTap && <GoogleOneTapSignInUp />}
      {!isMobile ? (
        <div className="header-hide-tab">
          <DesktopHeader />
        </div>
      ) : (
        <div className="header-hide-dektop">
          <MobileHeader />
        </div>
      )}
      <StickyBanner />
      {/* <ElectionFloatingWidget /> */}
    </GoogleOAuthProvider>
  );
};

export { Header };
